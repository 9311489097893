
export const getBrowserId = state => state.common.browserid;

export const getCustomerEmail = state => state.user && state.user.customer && state.user.customer.email;

export const getProducts = state => state.products && state.products.products;

export const getFeedbackFormQuestions = state => state.feedback.questions;

export const getPostThumbSuccess = state => state.feedback.postThumbSuccess;

export const getPostFormSuccess = state => state.feedback.postFormSuccess;

export const getLastRoom = state => state.user && state.user.lastRoom;

export const getRoomAvailability = state => state.user.availability;

export const getRoomBeingReserved = state => state.user.roomBeingReserved;

export const getCheckoutSession = state => state.products.session;