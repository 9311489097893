import { API_BASE_URL, END_POINTS } from '../../constants';
import { CookieUtil } from '../../util/CookieUtils';
import { setLastRoom, setRoomAvailability, setRoomFromSession } from '../actions/user.actions';

export const fetchRoomsUsed = () => {
    return async (dispatch, getState) => {
        const lastRoom = CookieUtil.getCookie('lastRoom');
        
        if (lastRoom) {
            dispatch(setLastRoom(lastRoom));
        }
    }
}

export const checkIfRoomAvailable = (room, browserid) => {
    return async(dispatch, getState) => {
        let options = {
            url: API_BASE_URL + END_POINTS.IS_ROOM_AVAILABLE + room,
            method: 'GET'
        };
        const headers = new Headers({
            'Content-Type': 'application/json',
            mode: 'cors',
            browserid: browserid
        })
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        const res = await fetch(options.url, options);
        const json = await res.json();
        console.log(room, ' isRoomAvailable >> ', json);
        if (json.status === 'Available') {
            dispatch(setRoomAvailability({
                'name': room,
                'isAvailable': true
            }));
        } else {
            dispatch(setRoomAvailability({
                'name': room,
                'isAvailable': false
            }));
        }
    }
}

export const storeRoomInSession = (roomBeingReserved) => {
    return async (dispatch, getState) => {
        if (window.localStorage) {
            localStorage.setItem('roomBeingReserved', roomBeingReserved);
        }
    }
}

export const getRoomFromSession = () => {
    return async (dispatch, getState) => {
        if (window.localStorage) {
            const room = localStorage.getItem('roomBeingReserved');
            dispatch(setRoomFromSession(room));
        }
    }
}