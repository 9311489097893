// If running on local then API BASE URL is run on localhost. 
// export const API_BASE_URL =  'http://localhost:8080';

export const REDIRECT_URL = 'https://meetrocket.com'
export const API_BASE_URL = 'https://auth.meetrocket.com';

// export const API_BASE_URL = 'https://auth.meetrocket.com';
export const ACCESS_TOKEN = 'accessToken';

// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect';
export const OAUTH2_REDIRECT_URI = 'https://app.meetrocket.com/oauth2/redirect';
// export const OAUTH2_REDIRECT_URI = 'http://auth.meetrocket.com:3000/oauth2/redirect'

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const STRIPE_PK = 'pk_test_51InY8lFfyj6wKVNQnoOmuoHP6M398EbuNpz9M9zK7fjpcdkNP5ZFbSsuVBhx6h6LPWwxp9WaLSdhLgtYuvoeMcvO00cMnbioS6';
export const END_POINTS = {
    BROWSER_ID : '/browserid', // GET
    ROOM: '/room', //
    IS_ROOM_AVAILABLE: '/room/',
    SUBSCRIBE_EMAIL: '/prospect', // POST
    FEEDBACK_THUMB: '/experience/thumb', // POST
    FEEDBACK_DETAIL: '/experience/feedback', // POST
    FEEDBACK_QUESTIONS: '/experience/feedbackquestions', // GET
    CHECKOUT: '/payment/checkout', // POST
    PRODUCT_CONFIG: '/payment/config', // GET
    ACTIVITY_PAGE: '/activity/page', // '/page/activity',
    ACTIVITY_ROOM: '/activity/room', // '/room/activity',
};

export const ROUTES = {
    SUBSCRIBE_CHECK_AVAILABILITY: '/subscribe/check-availability',
    SUBSCRIBE_SUCCESS: '/subscribe/success',
    SUBSCRIBE_ERROR: '/subscribe/error',
    SUBSCRIBE_CANCEL: '/subscribe/cancel',
    SUBSCRIBE_PLANS: '/subscribe/plans',
    FEEDBACK_THUMB: '/feedback/thumb',
    FEEDBACK_COMPLETE: '/feedback/complete',
    FEEDBACK: '/feedback'
}