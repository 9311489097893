
export const CookieUtil = {

    getCookie: (key) => {
        const cookieStr = document.cookie;
        if (cookieStr) {
            let cookieVal;
            cookieVal = cookieStr.split('; ').find((item) => item.startsWith(key+'='));
            if (cookieVal) {
              cookieVal = cookieVal.split('=')[1];
                return cookieVal;  
            }
        }
        return null;
    },

    updateCookie: (key, value) => {
        document.cookie = `${key}=${value};path=/;expires=Fri, 31 Dec 2030 23:59:59 GMT;Secure;domain=.meetrocket.net`;
    }

};