import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { REDIRECT_URL } from '../constants';
import { setUser } from '../store/actions/user.actions';
import './Home.css';

const Home = () => {
    const { isAuthenticated, user, getAccessTokenSilently, context } = useAuth0();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
          dispatch(setUser(user))
        }
    }, [isAuthenticated]);

    return (
        <div className="home-container">
            <div className="container">
                <div className="graf-bg-container">
                    {isAuthenticated ? <><img src={user.picture} alt={user.name} /> <h1 className="home-title">{`Welcome ` + user.name }</h1></> : '' }
                    <p className="body-copy">
                        Meetrocket.com provides FREE, 100% encrypted one-to-one video calls in dedicated rooms. You do not need an account or download software. Simply grab a room to start a video call. Use it all day, every day for free.
                    </p>
                    <Button id="hostBtn" onClick={goToMRHome} color="primary" variant="outlined">Host a video visit</Button>
                </div>
            </div>
        </div>
    )
}

function goToMRHome() {
    window.location.href = REDIRECT_URL;
}

async function handleSubmit(event, getAccessTokenSilently) {
    let isAuthenticated;
    // console.log('context >>> ', context);
    if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
            samplePost(accessToken);
        }
    }
    // window.location.href = 'https://meetrocket.com';
    else {
        console.log('no access token found redirecting to landing page.');
        window.location.href = 'https://meetrocket.com';
    }
}

async function samplePost(accessToken) {
    const reqObj = {
        domain: 'https://auth.meetrocket.net',
        url: '/api/private',
        data: {
            page: 'app-home',
            info: JSON.stringify({})
        }
    }

    try {
        const res = await fetch(reqObj.domain + reqObj.url, {
            method: 'get',
            // body: JSON.stringify(reqObj.data),
            mode: 'cors',
            headers: {
                'browserid': 'ebdc0fe9-ff6f-41b7-9813-f647780e59e4',
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        if (res && res.status) {
            console.log(res);
        }
        const jsonRes = await res.json();
        console.log('response > ', res, jsonRes);    
    } catch (error) {
        console.log('error > ', error);
    }

}

export default Home;