import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginRedirectComponent from '../common/LoginRedirectComponent';
import { ROUTES } from '../constants';
import { redirectStripeCheckout } from '../store/effects/product.effects';
import { getCheckoutSession, getRoomBeingReserved } from '../store/selectors/selectors';
import { loadStripe } from '@stripe/stripe-js';
import LoadingIndicator from '../common/LoadingIndicator';

const stripePromise = loadStripe("pk_test_51InY8lFfyj6wKVNQnoOmuoHP6M398EbuNpz9M9zK7fjpcdkNP5ZFbSsuVBhx6h6LPWwxp9WaLSdhLgtYuvoeMcvO00cMnbioS6");

function CheckoutError(props) {
    const { isAuthenticated, user, getAccessTokenSilently, context, isLoading } = useAuth0();
    const [shoulRedirect, setShouldRedirect] =  useState(false);
    const lastRoomUsed = useSelector(getRoomBeingReserved);
    const session = useSelector(getCheckoutSession);
    const dispatch = useDispatch();

    useEffect(() => {
        if (session && shoulRedirect) {
            dispatch(redirectStripeCheckout({session, stripePromise}));
        }
    }, [session, shoulRedirect]);

    const tryAgain = () => {
        // props.history.push({
        //     pathname: ROUTES.SUBSCRIBE_PLANS
        // })
        setShouldRedirect(true);
    }

    return (
        !isLoading ? isAuthenticated ?
        <div
            className="container text-center">
            <h1
                className="title product-header">
                We are sorry. Your payment did not go through.
            </h1>
            <p
                className="error-body">
                Please verify your payment information and try again.
            </p>
            <Button
                    className="plan-select"
                    onClick={tryAgain} color="primary" variant="contained">Let's try again</Button>
        </div> : <LoginRedirectComponent></LoginRedirectComponent> : <LoadingIndicator></LoadingIndicator>
    )
}

export default CheckoutError;