import React from 'react';
import {
    Button,
    Container
} from '@material-ui/core';
import { REDIRECT_URL } from '../constants';

function FeedbackComplete () {
    return (
        <Container maxWidth="md">
        <div
            className='text-center'>
        <h1
            className='h1 heading-text custom-margin-top-80'>
            { 'Thanks for your feedback!'}
        </h1>
        <br/>
        <div
            className='reserve-room-container custom-margin-top-50'>
            <p
                className='header-text-description exit-copy'>
                { 'We always improve the experience and review all feedback.' }
            </p>
            <p
                className='header-text-description exit-copy'>
                {/* { 'You will be redirected to home page in ' } { 5 + ' seconds.'}  */}
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <Button onClick={goToMRHome} color="primary" variant="outlined">Host a video visit</Button>
        </div>
        </div>
        </Container>
    )

    function goToMRHome() {
        window.location.href = REDIRECT_URL;
    }
}

export default FeedbackComplete;