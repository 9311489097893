
import {
    SET_LAST_ROOM,
    SET_ROOM_AVAILABILITY,
    SET_ROOM_FROM_SESSION,
    SET_USER,
    UPDATE_ROOM_BEING_RESERVED
} from '../actions/user.actions';

const initialState = {
    customer: null,
    lastRoom: null,
    availability: null,
    roomBeingReserved: null
}

function userReducer(state = initialState, action) {

    const { type, payload } = action;

    switch( type ) {

        case SET_USER:
            return {...state, customer: payload};

        case SET_LAST_ROOM:
            return {...state, lastRoom: payload, roomBeingReserved: payload};
        
        case SET_ROOM_AVAILABILITY:
            return {...state, availability: payload}

        case UPDATE_ROOM_BEING_RESERVED:
            return {...state, roomBeingReserved: payload}

        case SET_ROOM_FROM_SESSION:
            return {...state, roomBeingReserved: payload}

        default:
            return state;
    }
}

export default userReducer;