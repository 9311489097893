import { Button } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastRoom, getRoomBeingReserved } from '../store/selectors/selectors';
import './Plans.css';
import LoadingIndicator from '../common/LoadingIndicator';
import { getRoomFromSession } from '../store/effects/user.effects';
import { REDIRECT_URL } from '../constants';

function CheckoutSuccess() {
    const dispatch = useDispatch();
    const [room, setRoom] = useState('');
    const lastRoomUsed = useSelector(getRoomBeingReserved);

    useEffect(() => {
        if (lastRoomUsed) {
            setRoom(lastRoomUsed);
        }
    }, [lastRoomUsed])

    useEffect (() => {
        dispatch(getRoomFromSession());
    }, [])

    const visitVideoRoom = () => {
        window.location.href = REDIRECT_URL + '/' + room;
    }

    return (
        room ?
        <div className="container">
            <h1 className="title product-header">
                Thank you for subscribing to Meetrocket.com
            </h1>
            <div className="text-center">
                <p className="fastest-text"><em>The fastest meetings on earth!</em></p>
                <section
                    className="success-body">
                    <p>
                        The room "<span className="room-name">{room}</span>" is all yours! <br/>
                        Go ahead, give it a try. Happy video visiting!
                    </p>
                </section>
                <Button
                    className="plan-select"
                    onClick={visitVideoRoom} color="primary" variant="contained">Go to my video room</Button>
            </div>
        </div> : <LoadingIndicator></LoadingIndicator>
    )
}

export default CheckoutSuccess;