import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts, getBrowserId, getCustomerEmail, getLastRoom, getRoomAvailability, getRoomBeingReserved, getCheckoutSession } from '../store/selectors/selectors';
import { fetchProducts, createCheckoutSession, redirectStripeCheckout } from '../store/effects/product.effects';
import './Plans.css';
import { checkIfRoomAvailable, getRoomFromSession } from '../store/effects/user.effects';
import { useAuth0 } from '@auth0/auth0-react';

const stripePromise = loadStripe("pk_test_51InY8lFfyj6wKVNQnoOmuoHP6M398EbuNpz9M9zK7fjpcdkNP5ZFbSsuVBhx6h6LPWwxp9WaLSdhLgtYuvoeMcvO00cMnbioS6");

function Plans (props) {
    const { isAuthenticated, user, getAccessTokenSilently, context, isLoading } = useAuth0();
    const dispatch = useDispatch();

    const browserId = useSelector(getBrowserId);
    const customerEmail = useSelector(getCustomerEmail); 

    const lastRoomUsed = useSelector(getRoomBeingReserved);
    // const isLastRoomAvailable = useSelector(getRoomAvailability);

    const[token, setToken] = useState(null);

    useEffect (() => {
        dispatch(getRoomFromSession());
    }, [])

    // useEffect(() => {
    //     if (lastRoomUsed) {
    //         dispatch(checkIfRoomAvailable(lastRoomUsed));
    //     }
    // }, [lastRoomUsed]);

    useEffect(() => {
        if (browserId && customerEmail && isAuthenticated) {
            getAccessTokenSilently()
                .then(token => dispatch(fetchProducts({browserId, token, customerEmail})));
        }
    }, [browserId, customerEmail, isAuthenticated]);

    const products = useSelector(getProducts);

    const initiateSession = (product) => {
        getAccessTokenSilently()
            .then(token => {
                dispatch(
                    createCheckoutSession({
                        stripePromise,
                        priceid: product.id,
                        browserid: browserId,
                        email: customerEmail,
                        token,
                        room: lastRoomUsed
                    })
                )
            });
    };

    const session = useSelector(getCheckoutSession);

    useEffect(() => {
        if (session) {
            dispatch(redirectStripeCheckout({
                stripePromise,
                session
            }));
        }
    }, [session]);

    return (
        <div className="container">
            <h1 className="product-header">
                Congratulations! The room <span className="room-name">"{lastRoomUsed}"</span> is available.<br/>
                Select a plan to make this video room yours.
            </h1>
            <div className="desc">
                        {
                            products && products.map(product => (
                                <div
                                    key={'container_' + product.product.id}
                                    className="text-center">
                                <div
                                    className="product-tab"
                                    id={product.id}
                                    key={product.product.id}>
                                    <h3>Personal</h3>
                                    <div
                                        className="product-detail">
                                        <div className="plan-amount">${0.99}</div>
                                        <div className="disclaimer">*</div>
                                        <div className="plan-duration">{"/mo"}</div>
                                    </div>
                                    <p className="disclaimer-text">*Billed annually in advance</p>
                                    <Button
                                        className="plan-select"
                                        onClick={() => initiateSession(product)} color="primary" variant="contained">Select</Button>
                                    <ul className="plan-info">
                                        <li>1-to-1 video room</li>
                                        <li>Dedicated room name of your choice</li>
                                        <li>Unlimited video visits</li>
                                        <li>No download or install necessary</li>
                                        <li>High quality audio and video </li>
                                        <li>Call any device from any device and OS</li>
                                        <li>End-to-end encryption</li>
                                        <li>One tap to join video room </li>
                                        <li>Desktop sharing</li>
                                        <li>1-to-1 Chat</li>
                                    </ul>
                                </div>
                                <br/>
                                <Button
                                        className="plan-select"
                                        onClick={() => initiateSession(product)} color="primary" variant="contained">Select</Button>
                                </div>
                            ))
                        }
                    </div>
        </div>
    );
    
}

export default Plans;