import React, { Component, Fragment } from 'react';
import './Login.css';
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL, ACCESS_TOKEN } from '../../constants';
// import { login } from '../../util/APIUtils';
import { Link, Redirect } from 'react-router-dom'
import fbLogo from '../../img/fb-logo.png';
import googleLogo from '../../img/google-logo.png';
import githubLogo from '../../img/github-logo.png';
import Alert from 'react-s-alert';
import {
    Button,
    TextField
} from '@material-ui/core';

class Login extends Component {
    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if(this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }
    
    render() {
        if(this.props.authenticated) {
            return <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
            }}/>;            
        }

        return (
            <div className="login-container">
                <div className="login-content">
                    <h1 className="login-title">Login</h1>
                    <SocialLogin />
                    <div className="or-separator">
                        <span className="or-text">OR</span>
                    </div>
                    <LoginForm {...this.props} />
                    <span className="signup-link">New user? <Link to="/signup">Sign up!</Link></span>
                </div>
            </div>
        );
    }
}

class SocialLogin extends Component {
    render() {
        return (
            <Fragment>
                <div className="social-login">
                    {/* <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                        <img src={googleLogo} alt="Google" /> Log in with Google</a> */}
                    <Button
                        id="googleLoginBtn"
                        variant="outlined"
                        href={GOOGLE_AUTH_URL}
                        color="inherit">
                        <span className="social-logo google"><img src={googleLogo} alt="Google" /></span>
                        Log in with Google</Button>
                </div>
                {/* <div className="social-login">
                    <Button
                        id="fbLoginBtn"
                        variant="outlined"
                        href={FACEBOOK_AUTH_URL}
                        color="inherit">
                        <span className="social-logo facebook"><img src={fbLogo} alt="Facebook" /></span>
                        Log in with Facebook</Button>
                </div> */}
                { /* Uncomment above code when FB is ready for use.. */ }
            </Fragment>
        );
    }
}


class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            errors: {}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        let input = {...this.state.input};
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        input[inputName] = inputValue;
  
        this.setState((state, props) => ({
            input: input
        }));

        // this.setState({
        //     [inputName] : inputValue
        // });      
    }

    handleSubmit(event) {
        event.preventDefault();   

        if (!this.validate()) {
            return;
        }

        const loginRequest = {...{'email': this.state.input.email, 'password': this.state.input.password}};

        // login(loginRequest)
        // .then(response => {
        //     localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        //     Alert.success("You're successfully logged in!");
        //     this.props.history.push("/");
        //     // window.location.href = 'https://meetrocket.com';
        // }).catch(error => {
        //     Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    
    validate() {
        let inputs = {...this.state.input};
        let errors = {};
        let isValid = true;
        // console.log('inputs ', inputs);

        if (!inputs['email']) {
            isValid = false;
            errors["email"] = "Please enter your email address.";
        }

        if (inputs['email'] !== undefined) {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(inputs["email"])) {
              isValid = false;
              errors["email"] = "Please enter a valid email address.";
            }
        }

        if (!inputs['password']) {
            isValid = false;
            errors['password'] = "Please enter your password.";
        }

        // if (inputs['password'] !== undefined && inputs['password'].length < 8) {
        //     isValid = false;
        //     errors['password'] = "Password is invalid.";
        // }

        console.log('errors: ', errors);

        this.setState({
            errors: errors
        });

        return isValid;
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    {/* <input type="email" name="email" 
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required/> */}
                    <TextField
                        error={!!this.state.errors.email}
                        aria-required="true"
                        className=""
                        id="emailInput"
                        name="email"
                        label="Email"
                        variant="outlined"
                        color="primary"
                        // value={this.state.input.email}
                        onChange={this.handleInputChange}
                    />
                    {!!this.state.errors.email ? <p className="error-text">{this.state.errors.email}</p>: ''}
                </div>
                <div className="form-item">
                    {/* <input type="password" name="password" 
                        className="form-control" placeholder="Password"
                        value={this.state.password} onChange={this.handleInputChange} required/> */}
                    <TextField
                        error={!!this.state.errors.password}
                        aria-required="true"
                        type="password"
                        id="passwordInput"
                        name="password"
                        label="Password"
                        variant="outlined"
                        color="primary"
                        // value={this.state.input.password}
                        onChange={this.handleInputChange}
                    />  
                    {!!this.state.errors.password ? <p className="error-text">{this.state.errors.password}</p>: ''}   
                </div>
                <div className="form-item">
                    {/* <button color="primary" variant="outlined" type="submit" className="btn btn-block btn-primary">Login</button> */}
                    <Button id="loginBtn" className="" onClick={this.handleSubmit} color="primary" variant="outlined">Login</Button>
                </div>
            </form>                    
        );
    }
}

export default Login
