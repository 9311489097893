import React,
  {
    Component,
    useEffect
  } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';

import { getBrowserId, getCustomerEmail, getLastRoom } from '../store/selectors/selectors';
import { fetchBrowserId } from '../store/effects/shared.effects';
import { fetchRoomsUsed } from '../store/effects/user.effects';
import { setUser } from '../store/actions/user.actions';

import AppHeader from '../common/AppHeader';
import AppFooter from '../common/AppFooter';
import Home from '../home/Home';
import Exit from '../exit/Exit';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Plans from '../checkout/Plans';
import Profile from '../user/profile/Profile';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import FeedbackPage from '../feedback/Feedback';
import FeedbackComplete from '../feedback/FeedbackComplete';
import CheckoutSuccess from '../checkout/CheckoutSuccess';
import CheckoutError from '../checkout/CheckoutError';
import ThankyouPage from '../thankyou/Thankyou';
import FeedbackThumb from '../feedback/FeedbackThumb';
import CheckRoomAvailability from '../checkout/CheckAvailability';
import { ROUTES } from '../constants';
import { setAccessToken } from '../store/actions/shared.actions';

function App (props) {
  const { isAuthenticated, user, getAccessTokenSilently, context, isLoading, getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  
  const browserId = useSelector(getBrowserId);
  const customerEmail = useSelector(getCustomerEmail);
  const lastRoom = useSelector(getLastRoom);

  useEffect(() => {
    if (!browserId) {
      dispatch(fetchBrowserId());
    }
  }, []);

  useEffect(() => {
    if (!lastRoom) {
      dispatch(fetchRoomsUsed());
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUser(user));
      getAccessTokenSilently().then(token => dispatch(setAccessToken(token)));
      getIdTokenClaims().then(claims => console.log('IDclaims', claims))
    }
  }, [isAuthenticated]);

  return (
    !isLoading ? 
    <div className="app">
      <div className="app-top-box">
        <AppHeader authenticated={isAuthenticated} onLogout={handleLogout} />
      </div>
      <div className="app-body">
      <Router>
        <Switch>
          <Route exact path="/" component={Home}></Route> { /* Commented to that user can be redirected to login page by default  */}
          <PrivateRoute path="/profile" authenticated={isAuthenticated} currentUser={user}
            component={Profile}></PrivateRoute>
          <PrivateRoute path={ROUTES.SUBSCRIBE_PLANS} authenticated={isAuthenticated} currentUser={user}
            component={Plans}></PrivateRoute>
          <PrivateRoute path={ROUTES.SUBSCRIBE_SUCCESS} authenticated={isAuthenticated} currentUser={user}
            component={CheckoutSuccess}></PrivateRoute>
          <PrivateRoute path={ROUTES.SUBSCRIBE_ERROR} authenticated={isAuthenticated} currentUser={user}
            component={CheckoutError}></PrivateRoute>
          <PrivateRoute path={ROUTES.SUBSCRIBE_CANCEL} authenticated={isAuthenticated} currentUser={user}
            component={CheckoutError}></PrivateRoute>
          <Route path="/exit"
            render={(props) => <Exit authenticated={isAuthenticated} {...props} />}></Route>
          <Route path="/thankyou"
            render={(props) => <ThankyouPage authenticated={isAuthenticated} {...props} />}></Route>
          <Route path={ROUTES.FEEDBACK_COMPLETE}
            render={(props) => <FeedbackComplete authenticated={isAuthenticated} {...props} />}></Route>
          <Route path={ROUTES.FEEDBACK_THUMB}
            render={(props) => <FeedbackThumb authenticated={isAuthenticated} {...props} />}></Route>
          <Route path={ROUTES.FEEDBACK}
            render={(props) => <FeedbackPage authenticated={isAuthenticated} {...props} />}></Route>
          {/* <PrivateRoute path="/plans" authenticated={isAuthenticated} currentUser={user}
            component={Plans}></PrivateRoute> */}
          {/* <Route path="/subscribe/plans"
            render={(props) => <Plans authenticated={isAuthenticated} {...props} />}></Route> */}
          {/* <Route path="/subscribe/success"
            render={(props) => <CheckoutSuccess authenticated={isAuthenticated} {...props} />}></Route> */}
          {/* <Route path="/subscribe/cancel"
            render={(props) => <CheckoutError authenticated={isAuthenticated} {...props} />}></Route> */}
          <Route path={ROUTES.SUBSCRIBE_CHECK_AVAILABILITY}
            render={(props) => <CheckRoomAvailability authenticated={isAuthenticated} {...props} />}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      </Router>
      </div>
      <div>
        <AppFooter />
      </div>
      <Alert stack={{limit: 3}} 
        timeout = {5000}
        position='top-right' effect='slide' offset={65} />
    </div> : <LoadingIndicator></LoadingIndicator>
  );

}

function handleLogout() {
  Alert.success("You're safely logged out!");
}

export default App;
