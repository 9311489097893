
import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers/rootReducer';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['feedback', 'user', 'products', 'common']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => createStore(
        persistedReducer,
        composeWithDevTools(
            applyMiddleware(thunk)
        )
        // window.__REDUX_DEVTOOLS_EXTENSION__ && 
        // window.__REDUX_DEVTOOLS_EXTENSION__()
    );
