import {
    getProductsSuccess,
    createCheckoutSessionSuccess
} from '../actions/product.actions';

import { API_BASE_URL, END_POINTS, STRIPE_PK } from '../../constants';

export function fetchProducts (params) {
    return async (dispatch, getState) => {
        let options = {
            url: API_BASE_URL + END_POINTS.PRODUCT_CONFIG,
            method: 'GET',
            mode: 'cors'
        };
        const headers = new Headers({
            'Content-Type': 'application/json',
            'browserid': params.browserid,
            'email': params.customerEmail,
            'Authorization': `Bearer ${params.token}`
        })
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        const res = await fetch(options.url, options);
        const json = await res.json();
        const products = json.prices;

        dispatch(getProductsSuccess(products));
    }
}

export function createCheckoutSession(params) {
    return async (dispatch, getState) => {
        const stripe = await params.stripePromise;
        const API = API_BASE_URL + END_POINTS.CHECKOUT;
        const options = {
            'method': 'post',
            'mode': 'cors',
            'body': JSON.stringify({
                priceid: params.priceid,
                email: params.email,
                room: params.room
            }),
            'headers': {
                'Content-Type': 'application/json',
                'browserid': params.browserid,
                'email': params.email,
                'Authorization': `Bearer ${params.token}`
            }
        };

        const response = await fetch(API, options);
        const session = await response.json();
        dispatch(createCheckoutSessionSuccess(session));
    }
}

export const redirectStripeCheckout = (params) => {
    return async (dispatch, getState) => {
        const stripe = await params.stripePromise;
        const result = await stripe.redirectToCheckout({
            sessionId: params.session.sessionid
        });
        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    }
}