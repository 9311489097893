import {
    getFeedbackQuestionsSuccess,
    postFeedbackFormSuccess,
    postFeedbackThumbSuccess
} from '../actions/feedback.actions';

import { API_BASE_URL, END_POINTS } from '../../constants';

export function getFeedbackQuestions() {
    return async (dispatch, getState) => {

        let options = {
            url: API_BASE_URL + END_POINTS.FEEDBACK_QUESTIONS,
            method: 'GET'
        };
        const headers = new Headers({
            'Content-Type': 'application/json',
            mode: 'cors',
        })
    
        const defaults = {headers: headers};
        options = Object.assign({}, defaults, options);
    
        const res = await fetch(options.url, options);
        const json = await res.json();
        dispatch(getFeedbackQuestionsSuccess(json))
    }
}

export function postThumb(params) {
    return async (dispatch, getState) => {
        const data = {
            'email': params.email,
            'thumb': params.feedback === 'up' ? true : false 
        }

        const API = API_BASE_URL + END_POINTS.FEEDBACK_THUMB;
        const options = {
            'method': 'post',
            'mode': 'cors',
            'body': JSON.stringify(data),
            'headers': {
                'Content-Type': 'application/json',
                'browserid': params.browserid
            }
        };

        const response = await fetch(API, options);
        const json = await response.json();
        dispatch(postFeedbackThumbSuccess(json));
    }
}

export function postFeedbackForm(params) {
    return async (dispatch, getState) => {

        const data = {
            'email': '',
            'feedbackitem': params.items,
            'feedbackmessage': params.comment,
            'info': JSON.stringify({}),
            'otherparticipantid': '',
            'participantid': '',
            'roomname': params.lastRoomUsed
        };

        const API = API_BASE_URL + END_POINTS.FEEDBACK_DETAIL;
        const options = {
            'method': 'post',
            'mode': 'cors',
            'body': JSON.stringify(data),
            'headers': {
                'Content-Type': 'application/json',
                'browserid': params.browserid
            }
        };

        const response = await fetch(API, options);
        const json = await response.json();
        dispatch(postFeedbackFormSuccess(true));
    }
}

