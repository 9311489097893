import React, { useEffect, useState } from 'react';

import {
    Button,
    Container
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useDispatch, useSelector } from 'react-redux';

import { postThumb } from '../store/effects/feedback.effects';
import { getBrowserId, getCustomerEmail, getPostThumbSuccess } from '../store/selectors/selectors';
import { Redirect } from 'react-router';
import { REDIRECT_URL, ROUTES } from '../constants';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

let currentValue = null;

function FeedbackThumb(props) {
    const classes = useStyles();
    const [open, setOpen ] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [thumb, setThumb] = useState();
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };
    let previousValue = currentValue;
    currentValue = useSelector(getPostThumbSuccess);
    if (previousValue !== currentValue && currentValue.message) {
        handleClose();
        // props.history.push("/feedback-complete");
        // return <Redirect to={{ pathname: '/feedback-complete' }} />
        setRedirect(true);
    }
    const dispatch = useDispatch();
    
    const browserId = useSelector(getBrowserId);
    const customerEmail = useSelector(getCustomerEmail);

    useEffect (() => {
        if (thumb === 'up') {
            window.location.href = REDIRECT_URL;
        }
    }, [currentValue])

    const thumbClick = (thumb) => {
        handleToggle();
        const data = {
            browserid: browserId,
            email: customerEmail,
            feedback: thumb
        }
        setThumb(thumb);
        dispatch(postThumb(data));
    }
    let component;
    /* if (redirect && thumb === 'up') {
        component = <Redirect to={{ pathname: ROUTES.FEEDBACK_COMPLETE}} />;
    } else */ if (redirect && thumb === 'down') {
        component = <Redirect to={{ pathname: ROUTES.FEEDBACK}} />;
    } else {
        component = <Container maxWidth="md">
        <div
            className='text-center'>
                <h1
                    className='h1 heading-text custom-margin-top-80'>
                    { 'How was your experience ?'}
                </h1>
                <div
                    className='feedback-buttons-container'>
                    <div>
                        <IconButton aria-label="thumb-down"
                            onClick={() => thumbClick('up') }>
                            <ThumbUpIcon fontSize="large" />
                        </IconButton>
                        <p className='invite-button-label'>{ 'Good' }</p>
                    </div>
                    <div>
                        <IconButton aria-label="thumb-down"
                            onClick={() => thumbClick('down') }>
                            <ThumbDownIcon fontSize="large" />
                        </IconButton>
                        <p className='invite-button-label'>{ 'Bad' }</p>
                    </div>
                </div>
                {/* <div
                    className='reserve-room-container'>
                    <p
                        className='header-text-description exit-copy'>
                        { 'Would you like your own room? ' }
                        <a
                            onClick={console.log}
                            className=''
                            href=''>
                            { 'Learn more' }
                        </a>
                    </p>
                </div> */}
            </div>
            {/* <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </Container>;
    }
    return component;
}

export default FeedbackThumb;
