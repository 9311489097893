import React from 'react';
import './AppFooter.css';
import Twitter from '../img/social/Twitter_white.svg';
import Facebook from '../img/social/Facebook_white.svg';
import Linkedin from '../img/social/LinkedIN_white.svg';
import Email from '../img/social/Mail_white.svg';
import Instagram from '../img/social/Instagram_white.svg';

const AppFooter = (props) => {
    return (
        <footer>
            <div className="footer-links">
                {/* <section>
                    <h3>Solutions</h3>
                    <ul>
                        <li><a href="index.php/solutions/personal">For Personal Use</a></li>
                        <li><a href="index.php/solutions/professional">For Professionals</a></li>
                    </ul>
                </section> */}
                <section>
                    <h3>Help &amp; Support</h3>
                    <ul>
                        <li><a href="https://c.meetrocket.com/index.php/help/getting-started">Getting started</a></li>
                        <li><a href="https://c.meetrocket.com/index.php/help/frequently-asked-questions">FAQs</a></li>
                    </ul>
                </section>
                <section>
                    <h3>Community</h3>
                    <ul>
                        <li><a href="https://c.meetrocket.com/index.php/blog">Blog</a></li>
                    </ul>
                </section>
                <section>
                    <h3>About</h3>
                    <ul>
                        <li><a href="https://c.meetrocket.com/index.php/about/mission">Mission</a></li>
                        <li><a href="https://c.meetrocket.com/index.php/about/company">Company</a></li>
                        <li><a href="https://c.meetrocket.com/index.php/about/terms-of-service">Terms of Service</a></li>
                        <li><a href="https://c.meetrocket.com/index.php/about/privacy">Privacy Policy </a></li>
                        <li><a href="https://c.meetrocket.com/index.php/about/contact-section">Contact</a></li>
                    </ul>
                </section>
                <section className="social-container">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/theteam.meetrocket.3"><span className="sr-only">Opens in a new window</span><img alt="Facebook" src={Facebook}></img></a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/in/lets-meetrocket-a92b55204/"><span className="sr-only">Opens in a new window</span><img alt="Linkedin" src={Linkedin}></img></a></li>
                        <li><a target="_blank" href="https://twitter.com/letsMeetrocket"><span className="sr-only">Opens in a new window</span><img alt="Twitter" src={Twitter}></img></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/LetsMeetrocket/"><span className="sr-only">Opens in a new window</span><img alt="Instagram" src={Instagram}></img></a></li>
                        <li><a target="_blank" href="https://c.meetrocket.com/index.php/about/contact-section"><span className="sr-only">Opens in a new window</span><img alt="Email" src={Email}></img></a></li>
                    </ul>
                </section>
            </div>
            <div className="social">
                <a target="_blank" href="https://www.facebook.com/theteam.meetrocket.3"><span className="sr-only">Opens in a new window</span><img src={Facebook}></img></a>
                <a target="_blank" href="https://www.linkedin.com/in/lets-meetrocket-a92b55204/"><span className="sr-only">Opens in a new window</span><img src={Linkedin}></img></a>
                <a target="_blank" href="https://twitter.com/letsMeetrocket"><span className="sr-only">Opens in a new window</span><img src={Twitter}></img></a>
                <a target="_blank" href="https://www.instagram.com/LetsMeetrocket/"><span className="sr-only">Opens in a new window</span><img src={Instagram}></img></a>
                <a target="_blank" href="https://c.meetrocket.com/index.php/about/contact-section"><span className="sr-only">Opens in a new window</span><img src={Email}></img></a>
            </div>
            <div>
                <p>© Copyright 2021 - Meetrocket.com</p>
            </div>
        </footer>
    );
}

const footerLinks = [
    {
        label: 'Solutions',
        url: '',
        items: [
            {
                label: 'For Personal Use',
                url: 'index.php/solutions/personal'
            },
            {
                label: 'For Professionals',
                url: 'index.php/solutions/professional'
            }
        ]
    },
    {
        label: 'Help & Support',
        url: '',
        items: [
            {
                label: 'Getting started',
                url: 'index.php/help/getting-started'
            },
            {
                label: 'FAQs',
                url: 'index.php/help/frequently-asked-questions'
            }
        ]
    },
    {
        label: 'Community',
        url: '',
        items: [
            {
                label: 'Blog',
                url: 'index.php/blog'
            }
        ]
    },
    {
        label: 'About',
        url: '',
        items: [
            {
                label: 'Mission',
                url: 'index.php/about/mission'
            },
            {
                label: 'Company',
                url: 'index.php/about/company'
            },
            {
                label: 'Terms of Service',
                url: 'index.php/about/terms-of-service'
            },
            {
                label: 'Privacy Policy',
                url: 'index.php/about/privacy'
            },
            {
                label: 'Contact',
                url: 'index.php/about/contact-section'
            }
        ]
    }
];

const generateFooterMarkup = () => {
    let str = '<ul>';
    footerLinks.forEach(element => {
        str = str + `<li>${element.label}</li>`
    });
    str += '</ul>';
    return str;
}

export default AppFooter;