import React, { Component } from 'react';
import './Signup.css';
import { Link, Redirect } from 'react-router-dom'
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, GITHUB_AUTH_URL } from '../../constants';
// import { signup } from '../../util/APIUtils';
import fbLogo from '../../img/fb-logo.png';
import googleLogo from '../../img/google-logo.png';
import githubLogo from '../../img/github-logo.png';
import Alert from 'react-s-alert';
import {
    Button,
    TextField
} from '@material-ui/core';

class Signup extends Component {
    
    render() {
        // console.log(this.props);
        if(this.props.authenticated) {
            return <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
            }}/>;            
        }

        return (
            <div className="signup-container">
                <div className="signup-content">
                    <h1 className="signup-title">Signup</h1>
                    {/* <SocialSignup />
                    <div className="or-separator">
                        <span className="or-text">OR</span>
                    </div> */}
                    <SignupForm {...this.props} />
                    <span className="login-link">Already have an account? <Link to="/login">Login!</Link></span>
                </div>
            </div>
        );
    }
}


class SocialSignup extends Component {
    render() {
        return (
            <div className="social-signup">
                <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
                    <img src={googleLogo} alt="Google" /> Sign up with Google</a>
                <a className="btn btn-block social-btn facebook" href={FACEBOOK_AUTH_URL}>
                    <img src={fbLogo} alt="Facebook" /> Sign up with Facebook</a>
            </div>
        );
    }
}

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {},
            errors: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        let input = {...this.state.input};
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;
        input[inputName] = inputValue;
  
        this.setState((state, props) => ({
            input: input
        }));       
    }

    handleSubmit(event) {
        event.preventDefault();   

        if (!this.validate()) {
            return;
        }

        const signUpRequest = {...{'email': this.state.input.email, 'name': this.state.input.name, 'password': this.state.input.password }};

        // signup(signUpRequest)
        // .then(response => {
        //     Alert.success("You're successfully registered. Please login to continue.");
        //     this.props.history.push("/login");
        // }).catch(error => {
        //     Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');            
        // });
    }

    validate() {
        let inputs = {...this.state.input};
        let errors = {};
        let isValid = true;
        // console.log('inputs ', inputs);
        if (!inputs['name']) {
            isValid = false;
            errors["name"] = "Please enter your name.";
        }

        if (!inputs['email']) {
            isValid = false;
            errors["email"] = "Please enter your email address.";
        }

        if (inputs['email'] !== undefined) {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(inputs["email"])) {
              isValid = false;
              errors["email"] = "Please enter a valid email address.";
            }
        }

        if (!inputs['password']) {
            isValid = false;
            errors['password'] = "Please enter your password.";
        }

        if (inputs['password'] !== undefined && inputs['password'].length < 8) {
            isValid = false;
            errors['password'] = "Password must contain atleast 8 characters.";
        }

        console.log('errors: ', errors);

        this.setState({
            errors: errors
        });

        return isValid;
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-item">
                    {/* <input type="text" name="name" 
                        className="form-control" placeholder="Name"
                        value={this.state.name} onChange={this.handleInputChange} required/> */}
                    <TextField
                        error={!!this.state.errors.name}
                        className=""
                        id="nameInput"
                        name="name"
                        label="Name"
                        variant="outlined"
                        color="primary"
                        // value={this.state.input.name}
                        onChange={this.handleInputChange}
                    />
                    {!!this.state.errors.name ? <p className="error-text">{this.state.errors.name}</p>: ''}
                </div>
                <div className="form-item">
                    {/* <input type="email" name="email" 
                        className="form-control" placeholder="Email"
                        value={this.state.email} onChange={this.handleInputChange} required/> */}
                    <TextField
                        error={!!this.state.errors.email}
                        className=""
                        id="emailInput"
                        name="email"
                        label="Email"
                        variant="outlined"
                        color="primary"
                        // value={this.state.input.email}
                        onChange={this.handleInputChange}
                    />
                    {!!this.state.errors.email ? <p className="error-text">{this.state.errors.email}</p>: ''}
                </div>
                <div className="form-item">
                    {/* <input type="password" name="password" 
                        className="form-control" placeholder="Password"
                        value={this.state.password} onChange={this.handleInputChange} required/> */}
                    <TextField
                        error={!!this.state.errors.password}
                        type="password"
                        className=""
                        id="passwordInput"
                        name="password"
                        label="Password"
                        variant="outlined"
                        color="primary"
                        // value={this.state.input.password}
                        onChange={this.handleInputChange}
                    />
                    {!!this.state.errors.password ? <p className="error-text">{this.state.errors.password}</p>: ''}
                </div>
                <div className="form-item">
                    {/* <button color="primary" variant="outlined" type="submit" className="btn btn-block btn-primary" >Sign Up</button> */}
                    <Button id="signupBtn" className="" onClick={this.handleSubmit} color="primary" variant="outlined">Sign Up</Button>
                </div>
            </form>                    

        );
    }
}

export default Signup