import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

function LoginRedirectComponent () {

    const { isAuthenticated, user, getAccessTokenSilently, context, loginWithRedirect } = useAuth0();
    useEffect(() => {
        loginWithRedirect({
            redirectUri: window.location.origin
        });
    })

    return <></>;
}

export default LoginRedirectComponent;