import { combineReducers } from 'redux'
import commonReducer from './common.reducer';
import feedbackReducer from './feedback.reducer';
import productReducer from './product.reducer';
import userReducer from './user.reducer';

const rootReducer = combineReducers({
    user: userReducer,
    common: commonReducer,
    feedback: feedbackReducer,
    products: productReducer
});

export default rootReducer;
