import React, { Component } from 'react';
import './Exit.css';
import {
    Button,
    Container,
    TextField,
    Checkbox
} from '@material-ui/core';
// import { captureEmail } from '../util/APIUtils';
import { API_BASE_URL, ACCESS_TOKEN, END_POINTS } from '../constants';
class Exit extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.elmChange = this.elmChange.bind(this);
        this.state = {
           value: {
               email: '',
               subscribe: true,
               emailcaptured: false
           },
           errors: {
               email: ''
           }

        };
    }

    elmChange(evt) {
        let currState = {...this.state};
        // console.log(evt.target.name, evt.target.value, currState);
        if (evt.target.name === 'subscribe') {
            currState.value.subscribe = !currState.value.subscribe;
        } else {
            const name = evt.target.name;
            const val = evt.target.value;
            currState.value[name] = val;
        }

        this.setState(currState);
    }

    handleSubmit(evt) {
        let errorOnPage = false;
        const currState = this.state;
        const errors = {};
        const email = this.state.value.email;
        if (!email) {
            errorOnPage = true;
            errors.email = 'Please enter an email address.';
        } else if (!this.validateEmail(email) || (email.length > 64)) {
            errorOnPage = true;
            errors.email = 'Please enter a valid email address.';
        }
        currState.errors = errors;
        this.setState(currState);

        if (!errorOnPage) {
            this.subscribeToEmail();
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    }

    async subscribeToEmail() {
        const currState = this.state;
        const errors = {};

        const req = {
          ...currState.value,
          roomname: '',
          referraluri: '',
          info: {}
        };

        const response = await this.captureEmail(req);
        if (response.ok) {
            this.setState({
                emailcaptured: true
            });
            // Redirect to thank you for email page.
            this.props.history.push({
                pathname: '/thankyou',
                state: {
                  action: 'subscribed'
                }
              });
        } else if (response.status >= 400 && response.status <= 499) {
            errors.email = 'Please enter a valid email address.';
            currState.errors = errors;
            this.setState(currState);
        }
    }

    captureEmail(reqParam) {
        const data = {
            email: reqParam.email,
            subscribenewsletter: reqParam.subscribe,
            roomname: reqParam.roomname,
            referraluri: reqParam.referraluri,
            info: JSON.stringify(reqParam.info)
        };
    
        const API = API_BASE_URL + END_POINTS.SUBSCRIBE_EMAIL;
        const options = {
            'method': 'post',
            'mode': 'cors',
            'body': JSON.stringify(data),
            'headers': {
                'Content-Type': 'application/json',
                'browserid': ''
            }
        };

        return fetch(API, options);
    }

    render() {
        return (
            <div className="home-container">
                <div className="container">
                { !this.state.value.emailcaptured ?
                    <Container maxWidth="md">
                        {/* <Button onClick={() => { console.log(this) }} color="primary" variant="outlined">Host your video conversation</Button> */}
                        <h1 className="title">Thanks for using Meetrocket.com!</h1>
                        <p className="body-text">
                        Would you like to host your own FREE video conversations? <br />
                        Enter your email below and we'll send you the free Meetrocket.com Video Host Guide.
                        </p>
                        <form className="exit-form" noValidate autoComplete="off">
                            <div className="email-input-container">
                                <TextField
                                    onChange={this.elmChange}
                                    id="email-input"
                                    name="email"
                                    label="Enter your email"
                                    variant="outlined"
                                    color="primary"
                                    error={!!this.state.errors.email}
                                    helperText={this.state.errors.email}
                                />
                                <p className="body-text">We take privacy seriously and do not share or sell your information.</p>
                                <label className="body-text">
                                    <Checkbox checked={!!this.state.value.subscribe} onChange={this.elmChange} name="subscribe" />
                                    Sign me up for the Meetrocket.com newsletter.
                                </label>
                            </div>
                            <div className="btn-container">    
                                <Button onClick={this.handleSubmit} color="primary" variant="outlined">Send me instructions</Button>
                            </div>
                        </form>
                    </Container> :
                    <Container>
                        <h1>Done! Your video host guide is on it's way! Check your inbox.</h1>
                    </Container> }
                </div>
            </div>
        )
    }
}

export default Exit;