import {
    getBrowserIdSuccess
} from '../actions/shared.actions';

import { API_BASE_URL, END_POINTS } from '../../constants';
import { CookieUtil } from '../../util/CookieUtils';

export function fetchBrowserId () {
    return async (dispatch, getState) => {
        // dispatch(progress())
        let browserid = CookieUtil.getCookie('browserid');
        if (browserid) {
            dispatch(getBrowserIdSuccess(browserid))
        } else {
            let options = {
                url: API_BASE_URL + END_POINTS.BROWSER_ID,
                method: 'GET'
            };
            const headers = new Headers({
                'Content-Type': 'application/json',
                mode: 'cors',
            })
        
            const defaults = {headers: headers};
            options = Object.assign({}, defaults, options);
        
            const res = await fetch(options.url, options);
            const json = await res.json();
            browserid = json.browserid;
            dispatch(storeInCookie(json));
            dispatch(getBrowserIdSuccess(browserid))
        }
    }
}

// export function setAccessToken (accessToken) {
//     return async(dispatch, getState) => {
//         // set token in cookie.
//         CookieUtil.updateCookie('accessToken', accessToken);
//     }
// }

export function storeInCookie (param) {
    return async (dispatch, getState) => {
        // store it in cookie for other domin to use.
        let cookieVal = param.browserid;
        CookieUtil.updateCookie('browserid', cookieVal);
    }
}
