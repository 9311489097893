export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';

export const SET_LAST_ROOM = 'SET_LAST_ROOM';
export const SET_ROOM_AVAILABILITY = 'SET_ROOM_AVAILABILITY';

export const UPDATE_ROOM_BEING_RESERVED = 'UPDATE_ROOM_BEING_RESERVED';
export const SET_ROOM_FROM_SESSION = 'SET_ROOM_FROM_SESSION';

export const getUser = () => {
    return {
        type: GET_USER
    }
}

export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: user
    }
}

export const setLastRoom = (room) => {
    return {
        type: SET_LAST_ROOM,
        payload: room
    }
}

export const setRoomAvailability = (flag) => {
    return {
        type: SET_ROOM_AVAILABILITY,
        payload: flag
    }
}

export const updateRoomBeingReserved = (room) => {
    return {
        type: UPDATE_ROOM_BEING_RESERVED,
        payload: room
    }
}

export const setRoomFromSession = (room) => {
    return {
        type: SET_ROOM_FROM_SESSION,
        payload: room
    }
}