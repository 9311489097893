
export const GET_FEEDBACK_QUESTIONS = 'GET_FEEDBACK_QUESTIONS';
export const GET_FEEDBACK_QUESTIONS_SUCCESS = 'GET_FEEDBACK_QUESTIONS_SUCCESS';
export const GET_FEEDBACK_QUESTIONS_ERROR = 'GET_FEEDBACK_QUESTIONS_ERROR';

export const POST_FEEDBACK_THUMB = 'POST_FEEDBACK_THUMB';
export const POST_FEEDBACK_THUMB_SUCCESS = 'POST_FEEDBACK_THUMB_SUCCESS';
export const POST_FEEDBACK_THUMB_ERROR = 'POST_FEEDBACK_THUMB_ERROR';

export const POST_FEEDBACK_FORM = 'POST_FEEDBACK_FORM';
export const POST_FEEDBACK_FORM_SUCCESS = 'POST_FEEDBACK_FORM_SUCCESS';
export const POST_FEEDBACK_FORM_ERROR = 'POST_FEEDBACK_FORM_ERROR';

export const getFeedbackQuestions = () => {
    return {
        type: GET_FEEDBACK_QUESTIONS
    }
}

export const getFeedbackQuestionsSuccess = (questions) => {
    return {
        type: GET_FEEDBACK_QUESTIONS_SUCCESS,
        payload: questions
    }
}

export const getFeedbackQuestionsError = (error) => {
    return {
        type: GET_FEEDBACK_QUESTIONS_ERROR,
        payload: error
    }
}

export const postFeedbackThumb = (thumb) => {
    return {
        type: POST_FEEDBACK_THUMB,
        payload: thumb
    }
}

export const postFeedbackThumbSuccess = (thumb) => {
    return {
        type: POST_FEEDBACK_THUMB_SUCCESS,
        payload: thumb
    }
}

export const postFeedbackThumbError = (error) => {
    return {
        type: POST_FEEDBACK_THUMB_ERROR,
        payload: error
    }
}

export const postFeedbackForm = (feedbackForm) => {
    return {
        type: POST_FEEDBACK_FORM,
        payload: feedbackForm
    }
}

export const postFeedbackFormSuccess = (feedbackForm) => {
    return {
        type: POST_FEEDBACK_FORM_SUCCESS,
        payload: feedbackForm
    }
}

export const postFeedbackFormError = (error) => {
    return {
        type: POST_FEEDBACK_FORM_ERROR,
        payload: error
    }
}