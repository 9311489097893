import {
    GET_FEEDBACK_QUESTIONS_SUCCESS,
    GET_FEEDBACK_QUESTIONS_ERROR,
    POST_FEEDBACK_FORM_ERROR,
    POST_FEEDBACK_FORM_SUCCESS,
    POST_FEEDBACK_THUMB_SUCCESS,
    POST_FEEDBACK_THUMB_ERROR
}  from '../actions/feedback.actions';

const initialState = {
    questions: [],
    getQuestionsError: null,
    postThumbSuccess: null,
    postThumbError: null,
    postFormSuccess: null,
    postFormError: null
}

const feedbackReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch(type) {

        case GET_FEEDBACK_QUESTIONS_ERROR: 
            return {...state, getQuestionsError: payload}

        case GET_FEEDBACK_QUESTIONS_SUCCESS:
            return {...state, questions: payload}

        case POST_FEEDBACK_FORM_ERROR:
            return {...state, postFormError: payload}

        case POST_FEEDBACK_FORM_SUCCESS:
            return {...state, postFormSuccess: true}
        
        case POST_FEEDBACK_THUMB_ERROR:
            return {...state, postThumbError: payload}

        case POST_FEEDBACK_THUMB_SUCCESS:
            return {...state, postThumbSuccess: payload}

        default: return state
    }
}

export default feedbackReducer;