import React from 'react';
import { NavLink } from 'react-router-dom';
import './AppHeader.css';
import watermark from '../img/watermark.png';
import LoginButton from '../components/LoginButton/LoginButton';
import LogoutButton from '../components/LogoutButton/LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import { REDIRECT_URL } from '../constants';

const AppHeader = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <header className="app-header">
            <div className="app-branding">
                <a href={REDIRECT_URL} className="app-title">
                    <img src={watermark} alt="Meetrocket"/>
                </a>
            </div>

            <nav className="app-nav"> 
                <ul>
                    <li>
                        {isAuthenticated ? <LogoutButton /> : <LoginButton /> }
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default AppHeader;