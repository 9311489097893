import {
    GET_BROWSER_ID_SUCCESS,
    GET_BROWSER_ID_ERROR,
    SET_ACCESS_TOKEN
} from '../actions/shared.actions';

const initialState = {
    browserid: null,
    token: null
}

function commonReducer (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {

        case GET_BROWSER_ID_SUCCESS:
            return {...state, browserid: payload};
        
        case SET_ACCESS_TOKEN:
            return {...state, token: payload};

        default:
            return state;

    }
}

export default commonReducer;