
import { 
    GET_PRODUCTS,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_ERROR,
    CREATE_CHECKOUT_SESSION_SUCCESS
} from '../actions/product.actions';

const initialState = {
    products: [],
    session: null
};

function productReducer (state = initialState, action) {
    
    const { type, payload } = action;

    switch(type) {

        case GET_PRODUCTS_SUCCESS:
            return {...state, products: payload}

        case GET_PRODUCTS_ERROR:
            return {...state, error: payload};

        case CREATE_CHECKOUT_SESSION_SUCCESS:
            return {...state, session: payload};

        default:
            return state;
    }
}

export default productReducer;