import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import {
    createMuiTheme,
    ThemeProvider
} from '@material-ui/core';
import { Auth0Provider } from '@auth0/auth0-react';
import { configureStore } from './store/store';
import { ROUTES } from './constants';

const store = configureStore();
const persistor = persistStore(store);

const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#FF4F04"
      },
      secondary: {
        main: "#FFFEFE"
      }
    }
  });

ReactDOM.render(
    <Auth0Provider
      domain="mrauth-demo.us.auth0.com"
      clientId="sfgmcaEVch72Zab0nvXuVaGcUp2ojXtA"
      audience="https://mrauth-demo.us.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata read:rooms create:rooms openid email profile"
      redirectUri={window.location.origin + ROUTES.SUBSCRIBE_PLANS}>
      <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate
              loading={<div>Loading...</div>}
              persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
      </ThemeProvider>
    </Auth0Provider>, 
    document.getElementById('root')
);

registerServiceWorker();
