import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import {
    Route,
    Redirect
  } from "react-router-dom";
import { ROUTES } from '../constants';
import LoadingIndicator from './LoadingIndicator';
import LoginRedirectComponent from './LoginRedirectComponent';
  
  
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  const { isAuthenticated, user, getAccessTokenSilently, context, loginWithRedirect, isLoading } = useAuth0();
  let component = <Route render={(props) => <Component {...rest} {...props} />}></Route>;
  return (
    isLoading ? <>
      <div>
        <LoadingIndicator></LoadingIndicator>
      </div> 
    </> : isAuthenticated ? <Route render={(props) => <Component {...rest} {...props} />}></Route> : <>
      <LoginRedirectComponent></LoginRedirectComponent>
    </>
  )
};
  
export default PrivateRoute