import React, { useEffect, useState } from 'react';
import './Feedback.css';
import {
    Button,
    Container
} from '@material-ui/core';
import { getFeedbackQuestions, postFeedbackForm } from '../store/effects/feedback.effects';
import { getBrowserId, getFeedbackFormQuestions, getLastRoom, getPostFormSuccess } from '../store/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../constants';

const REDIRECT_TIMER = 5;

function FeedbackPage (props) {
    // const [comment, setComment] = useState('');
    const lastRoomUsed = useSelector(getLastRoom);
    const browserId = useSelector(getBrowserId);
    const formSuccess = useSelector(getPostFormSuccess);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFeedbackQuestions());
    }, [])

    useEffect(() => {
        if (formSuccess) {
            props.history.push({
                pathname: ROUTES.FEEDBACK_COMPLETE
            })
        }
    }, [formSuccess])

    const questions = useSelector(getFeedbackFormQuestions);
    const checkBoxSet = new Map();
    let comment = '';
    const [commentError, setError] = useState(false);

    function onChange(evt) {
        // console.log(evt.target && evt.target.name, evt.target.value);
        const elm = evt.target;
        const elmName = evt.target.name;
        if (elmName === 'comment') {
            comment = evt.target.value;
        } else {
            if (checkBoxSet.has(elmName)) {
                checkBoxSet.delete(elmName)
            } else {
                checkBoxSet.set(elmName, elmName);
            }
        }
        
    }

    function onSubmit() {
        const feedbackItems = Array.from(checkBoxSet.values());
        const commentValidator = /^[\w\-\s]+$/;
        if (comment && !comment.match(commentValidator)) {
            // set error on comment.
            setError(true);
            console.log('error');
            return;
        } else {
            setError(false);
            console.log(feedbackItems, comment);
            dispatch(postFeedbackForm({
                items: feedbackItems,
                comment,
                lastRoomUsed,
                browserid: browserId
            }));
        }
    }

    return (
    <Container maxWidth="md">
        <div
            className='text-center'>
            
            <div className="feedback-capture-form">
                <h1
                    className='h1 heading-text custom-margin-top-80'>
                    { 'What went wrong?'}
                </h1>
                <form className='feedback-form'>
                    <div className='check-questions'>

                    {questions && questions.map((question, index) => 
                        <label
                            name={'questions'}
                            key={ 'q_' + question.key }
                            tabIndex='-1'
                            className='d-block checkbox-container'>
                            <input
                                onChange={onChange}
                                id={question.key}
                                name={question.key}
                                type='checkbox'
                            />
                            <span className='checkmark'></span>
                            &nbsp;&nbsp;
                            { question.message}
                        </label>
                    )}
                    </div>
                    <div
                        className="comment-container">
                        <textarea
                            onChange={onChange}
                            name="comment"
                            id="comment"
                            maxLength={200}
                            className={commentError ? "error-field": ""}
                            placeholder='Additional comments...'>
                        </textarea>
                        { commentError ? <span className="error-text">Only letters and numbers.</span> : ''}
                    </div>
                    <div>
                        <Button onClick={onSubmit} color="primary" variant="outlined">Submit</Button>
                    </div>
                </form>
            </div>
        </div>
    </Container>)
}
export default FeedbackPage;