export const GET_BROWSER_ID = 'GET_BROWSER_ID';
export const GET_BROWSER_ID_SUCCESS = 'GET_BROWSER_ID_SUCCESS';
export const GET_BROWSER_ID_ERROR = 'GET_BROWSER_ID_ERROR';

export const getBrowserId = () => {
    return {
        type: GET_BROWSER_ID
    }
}

export const getBrowserIdSuccess = (browserid) => {
    return {
        type: GET_BROWSER_ID_SUCCESS,
        payload: browserid
    }
}

export const getBrowserIdError = (error) => {
    return {
        type: GET_BROWSER_ID_ERROR,
        payload: error
    }
}

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const setAccessToken = (token) => {
    return {
        type: SET_ACCESS_TOKEN,
        payload: token
    }
}
