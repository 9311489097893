import React from 'react';
import {
    Button,
    Container
} from '@material-ui/core';

function ThankyouPage (props) {
    let action;
    if (props.location) {
        action = props.location.state && props.location.state.action;
    }

    return (
        <div className="container">
            <Container maxWidth="md">
                <h1 className="title">
                    { action === 'subscribed' ? 'Done! Your video host guide is on it\'s way! Check your inbox.' : 'Thank you for your business.'}
                </h1>
            </Container>
        </div>
    );
}

export default ThankyouPage;