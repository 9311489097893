import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrowserId, getLastRoom, getRoomAvailability } from '../store/selectors/selectors';
import { checkIfRoomAvailable, storeRoomInSession } from '../store/effects/user.effects';
import './Plans.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router';
import { updateRoomBeingReserved } from '../store/actions/user.actions';
import { ROUTES } from '../constants';
import Plans from './Plans';

function CheckRoomAvailability(props) {

    const { isAuthenticated, user, getAccessTokenSilently, context, loginWithRedirect, isLoading } = useAuth0();

    const dispatch = useDispatch();
    const browserId = useSelector(getBrowserId);
    const lastRoomUsed = useSelector(getLastRoom);
    const rooms = useSelector(getRoomAvailability);

    const [isRoomAvailable, setAvailability] = useState(true);
    const [room, setRoom] = useState('');
    const [roomError, setRoomError] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (lastRoomUsed) {
            setRoom(lastRoomUsed);
        }
    }, [lastRoomUsed]);
    
    useEffect(() => {
        if (rooms) {
            console.log(room, rooms);
            const avb = rooms.isAvailable;
            setAvailability(avb);
            if (avb) {
                dispatch(storeRoomInSession(room))
                if (isAuthenticated) {
                    props.history.push({
                        pathname: ROUTES.SUBSCRIBE_PLANS
                    })
                } else {
                    // dispatch(storeRoomInSession(room));
                    // props.history.push({
                        loginWithRedirect(
                            // {
                            //     'redirect_uri': window.location.origin + ROUTES.SUBSCRIBE_PLANS + '/'
                            // }
                        );
                    // })
                }
            } else {
                setRoomError(true);
            }
        }
    }, [rooms]);

    const elmChange = (evt) => {
        let roomVal = evt.target.value;
        setRoom(roomVal);
    
        if (!roomVal) {
            setRoomError(true);
            return;
        }
        const nameValidator = /^\w+$/;
        if (!roomVal.match(nameValidator)) {
            setRoomError(true);
            return;
        }
        setRoomError(false);
        setMessage('');
    }

    const keepRoom = () => {
        if (room) {
            setRoomError(false);
            setMessage('');
            dispatch(checkIfRoomAvailable(room, browserId));
            dispatch(updateRoomBeingReserved(room));
            // if (isRoomAvailable) {
            //     if (isAuthenticated) {
            //         props.history.push({
            //             pathname: ROUTES.SUBSCRIBE_PLANS
            //         })
            //     } else {
            //         // props.history.push({
            //             loginWithRedirect({
            //                 redirectUri: window.location.origin + ROUTES.SUBSCRIBE_PLANS
            //             });
            //         // })
            //     }
            // } else {
            //     setRoomError(true);
            // }
        } else {
            setRoomError(true);
            setMessage('Please enter a room name');
        }
    }

    const exit = () => {
        props.history.push({
            pathname: '/'
        })
    }

    return (
        !isLoading ? 
        <div className="container">
            <h1 className="title product-header">
                Thank you for using Meetrocket!<br/>
                {!isRoomAvailable ? ' Sorry this room is not available for subscription' : '' }
            </h1>
            <div className="text-center body-copy">
                <p>
                {!isRoomAvailable ? 
                    'Would you like to reserve another room?' 
                 : 'Would you like to keep this room by subscribing?' }</p>
                <section
                    className="room-section">
                    <TextField
                        onChange={elmChange}
                        id="room-input"
                        name="room"
                        label="Room name"
                        variant="outlined"
                        color="primary"
                        value={room}
                        error={!!roomError}
                        helperText={message}
                    />
                </section>
                <Button
                    className="plan-select"
                    onClick={keepRoom} color="primary" variant="contained">i want to keep this room</Button>
                <br/>
                <Button
                    className="plan-select"
                    onClick={exit} variant="text">No thanks</Button>
            </div>
        </div> : <LoadingIndicator></LoadingIndicator>
    )
}

export default CheckRoomAvailability;