
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';


export const CREATE_CHECKOUT_SESSION = 'CREATE_CHECKOUT_SESSION';
export const CREATE_CHECKOUT_SESSION_SUCCESS = 'CREATE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_CHECKOUT_SESSION_ERROR = 'CREATE_CHECKOUT_SESSION_ERROR';

export const getProducts = () => {
    return {
        type: GET_PRODUCTS
    }
}

export const getProductsSuccess = (products) => {
    return {
        type: GET_PRODUCTS_SUCCESS,
        payload: products
    }
}

export const getProductsError = (error) => {
    return {
        type: GET_PRODUCTS_ERROR,
        payload: error
    }
}

export const createCheckoutSession = (productDetail) => {
    return {
        type: CREATE_CHECKOUT_SESSION,
        payload: productDetail
    }
}

export const createCheckoutSessionSuccess = (session) => {
    return {
        type: CREATE_CHECKOUT_SESSION_SUCCESS,
        payload: session
    }
}

export const createCheckoutSessionError = (error) => {
    return {
        type: CREATE_CHECKOUT_SESSION_ERROR,
        payload: error
    }
}